<template>
    <el-container class="reset-password-page">
    <!-- <el-header class="header-box">
    </el-header> -->
        <div class="logo-img"></div>
    <el-main class="main-box">
        <h1 class="title">{{$t('Modify Password')}}</h1>
        <el-form 
            :model="form"
            :rules="rules"
            ref="formRef">
            <el-form-item prop="username">
                <el-input 
                    class="input-box"
                    :placeholder="($t('Account'))" 
                    v-model="form.username"></el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input 
                    class="input-box"
                    :placeholder="($t('Password'))"
                    v-model="form.password"
                    type="password"
                    show-password></el-input>
            </el-form-item>
            <el-form-item prop="newPassword">
                <el-input
                    class="input-box"
                    :placeholder="($t('New Password'))"
                    v-model="form.newPassword"
                    type="password"
                    show-password></el-input>
            </el-form-item>
            <el-form-item prop="confirmNewPassword">
                <el-input
                    class="input-box"
                    :placeholder="($t('Confirm New Password'))"
                    v-model="form.confirmNewPassword"
                    type="password"
                    show-password></el-input>
            </el-form-item>
            <el-form-item prop="inputCaptcha">
                <div class="captcha-box">
                    <el-input 
                        :placeholder="($t('captcha'))" 
                        class="captcha-input" 
                        v-model="form.inputCaptcha" 
                        auto-complete="off">
                    </el-input>
                    <img class="captcha-img" :src="captchaSrc" @click="queryCaptcha"/>
                </div>
            </el-form-item>
            <el-form-item>
                <el-button 
                    class="modify-button" 
                    type="primary"
                    @click="submitForm(formRef)">
                    {{ $t('Confirm Modify') }}
                </el-button>
            </el-form-item>
        </el-form>
    </el-main>
    <el-footer class="footer-box">
        <span class="policy-text">{{$t('Terms of use')}}</span>
        <span class="split-line"></span>
        <span class="policy-text">{{$t('Privacy policy')}}</span></el-footer>
    </el-container>
  </template>
  

<script setup>
import {reactive, ref, onBeforeMount} from 'vue';
import loginApi from '../../api/loginApi';
import { useRoute,useRouter } from 'vue-router';

const currentRoute = useRoute()
const router = useRouter()
const formRef = ref(null)
const form = reactive({
    username: '',
    password: '',
    newPassword: '',
    confirmNewPassword: '',
    inputCaptcha: ''
})

// const validatePassword = (rule,value,callback) => {
//     const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/;

//     if(value == ''){
//         callback(new Error('Is required'))
//     }else if (!passwordRegex.test(value)) {
//         callback(new Error('password rule'))
//     } else {
//         callback()
//     }
// };

const validateNewPassword = (rule,value,callback) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/;

    if(value == ''){
        callback(new Error('Is required'))
    }else if (!passwordRegex.test(value)) {
        callback(new Error('密码需包含英文大小写和数字，且长度最少为6'))
    } else {
        if(value == form.password){
            callback(new Error('与原密码一致'))
        }else{
            callback()
        }
    }
};

const validateConfirmPassword = (rule,value,callback) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/;

    if(value == ''){
        callback(new Error('Is required'))
    }else if (!passwordRegex.test(value)) {
        callback(new Error('两次输入密码不一致'))
    } else {
        if(value != form.newPassword){
            callback(new Error(''))
        }else{
            callback()
        }
    }
};

const validateCaptcha = (rule, value,callback) => {
    console.log('validateCaptcha value = ',value);
    console.log('validateCaptcha captchaText = ',captchaText);
    if(value == ''){
        callback(new Error('Is required'))
    }
    else if (value.toLocaleLowerCase()!=(captchaText.value).toLocaleLowerCase()) {
        callback(new Error('captcha error'))
    } else {
        callback()
    }
}

const rules = reactive({
    username: [
        { required: true, message: 'Please input username', trigger: 'blur' },
    ],
    password: [
        { required: true, message: 'Please input password', trigger: 'blur' },
    ],
    newPassword: [
        { validator: validateNewPassword, trigger: 'blur' }
    ],
    confirmNewPassword: [
        { validator: validateConfirmPassword, trigger: 'blur' }
    ],
    inputCaptcha: [
        { validator: validateCaptcha, trigger: 'blur'}
    ]
})

const submitForm = async (formR) => {
    if (!formR) return
    formR.validate(async (valid) => {
        if (valid) {
            console.log('submit!')
            const param = {
                username: form.username,
                password: form.password,
                newPassword: form.newPassword
            }
            const res = await loginApi.modifyPassword(param)
            console.log('modifyPassword res = ',res)
            if(res.code == 0){
                alert('修改成功')
                router.push('/Login')
            }else{
                alert(res.msg)
            }
        } else {
            console.log('error submit!')
            return false
        }
    })
}

const captchaSrc = ref('')
const captchaText = ref('')

const queryCaptcha = async () => {
    const res = await loginApi.queryCaptcha()
    captchaSrc.value = res.data.img
    captchaText.value = res.data.captchaText
}

onBeforeMount(() => {
    console.log('currentRoute = ',currentRoute.query)
    if(currentRoute.query.client_id!=undefined
      &&currentRoute.query.redirect_uri!=undefined){
        localStorage.setItem('client_id',currentRoute.query.client_id)
        localStorage.setItem('redirect_uri',currentRoute.query.redirect_uri)
    }
    queryCaptcha()
}) 

</script>

<style scoped>
.reset-password-page{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header-box{
    flex-shrink: 0;
}
.main-box{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.logo-img{
  width: 100px;
  height: 100px;
  background-color: #19c37d;
  /* color: white; */
  text-align: center;
  line-height: 50px;
  font-weight: 900;
  margin-top: 32px;
  background: url('../assets/logo.png');
  background-size: 100% 100%;
  /* flex-shrink: 0; */
}

.title {
  font-size: 30px;
  /* margin-bottom: 20px; */
}

.input-box{
  width: 320px;
  height: 52px;
  /* margin-top: 16px; */
}
.captcha-box{
  display: flex;
}

.captcha-input{
  width: 200px;
  /* margin-top: 16px; */
}

.captcha-img{
  width: 120px;
  height: 50px;
  /* margin-top: 16px; */
  display: inline;
  object-fit: cover;
  padding: 1px;
  cursor: pointer;
}

.modify-button{
  width: 322px;
  height: 52px;
  background-color: #19c37d;
  border-color: #19c37d;
  margin-top: 16px;
  line-height: 52px;
  font-weight: 600;
  font-size: 20px;
  color: white;
  border-radius: 2px;
  cursor: pointer;
}
.footer-box{
    height: 50px;
    margin-bottom: 50px;
    line-height: 50px;
    display: flex;
    align-items: center;
  }

.policy-text{
color: #19c37d;
cursor: pointer;
}

.split-line{
height: 20px;
width: 1px;
display: inline-block;
background: black;
margin: 0 10px;
}
</style>